import websocketService from "services/WebsocketService";
import { WEBSOCKET_EVENT } from "constants/WebsocketConstants";

class UnreadMsgInfoAdapter {
  onUnreadMessageInfoReceived(callback) {
    websocketService.listenAndExecuteEvent(
      WEBSOCKET_EVENT.UNREAD_MSG_INGO,
      callback
    );
  }
}

const unreadMsgInfoAdapter = new UnreadMsgInfoAdapter();
export default unreadMsgInfoAdapter;
