import { useSelector } from "react-redux";
import { makeSelectControlledFeatures } from "redux/selectors/FeatureControl";
import { makeSelectCurrentUser } from "redux/selectors/Users";

const useIsFeatureClosed = (featureName) => {
  const controlledFeatures = useSelector(makeSelectControlledFeatures());
  const currentUser = useSelector(makeSelectCurrentUser());

  const feature = controlledFeatures?.find(
    (feature) => feature?.name === featureName
  );

  // no selected controlled features
  if (controlledFeatures?.length === 0) return false;

  // feature is enabled for all users
  if (feature?.enabled_for_all) return false;

  // feature is enabled for specific users
  if (!!controlledFeatures?.length && currentUser) {
    const userHasAccess =
      currentUser?.feature_access_list?.includes(featureName);

    if (userHasAccess) {
      return false;
    }

    return true;
  }

  return true;
};

export default useIsFeatureClosed;
