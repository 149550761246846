import ApiService from "./ApiService";
import { stringify } from "querystring";

const ENDPOINTS = {
  MERCHANT: "merchants/me/",
};

class MerchantService extends ApiService {
  getMerchant = (payload) => {
    return this.apiClient.get(`${ENDPOINTS.MERCHANT}?${stringify(payload)}`);
  };
}

export default new MerchantService();
