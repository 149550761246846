import { takeEvery, call, put, all, fork } from "redux-saga/effects";
import { GET_CONTROLLED_FEATURES } from "redux/constants/FeatureControl";
import FeatureControlService from "services/featureControlService";
import { setControlledFeatures } from "redux/actions/FeatureControl";

export function* getControlledFeatures() {
  yield takeEvery(GET_CONTROLLED_FEATURES, function* () {
    try {
      const response = yield call(FeatureControlService.getFeatures);
      yield put(setControlledFeatures(response.data.results));
    } catch (err) {
      console.log(err);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getControlledFeatures)]);
}
