import React, { Suspense, lazy } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Layout, Spin } from "antd";
import Loading from "components/shared-components/Loading";
import AppLoyaltyViews from "views/loyalty-app/app-loyalty-views";
import Cart from "views/loyalty-app/app-loyalty-views/Cart";

const HeaderNavPatient = lazy(() =>
  import("components/layout-components/HeaderNavPatient")
);

const { Content } = Layout;

export const AppLoyaltyLayout = () => {
  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  return (
    <Suspense fallback={<Spin />}>
      <Layout>
        <HeaderNavPatient />
        <div className={`app-content`}>
          <Cart />
          <Content>
            <AppLoyaltyViews />
          </Content>
        </div>
      </Layout>
    </Suspense>
  );
};

export default AppLoyaltyLayout;
