import {
  GET_CONTROLLED_FEATURES,
  SET_CONTROLLED_FEATURES,
} from "redux/constants/FeatureControl";

export const getControlledFeatures = (payload) => ({
  type: GET_CONTROLLED_FEATURES,
  payload,
});

export const setControlledFeatures = (payload) => ({
  type: SET_CONTROLLED_FEATURES,
  payload,
});
