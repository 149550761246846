import produce from "immer";
import { SET_CART, SET_CONTACT_ID } from "redux/constants/Cart";
import { getLocalStorageItem } from "utils/localStorage";

export const initialState = {
  cart: getLocalStorageItem("cart") || [],
  contactId: null,
};

const cartReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_CART:
        draft.cart = payload;
        break;
      case SET_CONTACT_ID:
        draft.contactId = payload;
        break;
      default:
        return state;
    }
  });

export default cartReducer;
