import { useState } from "react";
import extractOrderingFields from "helpers/extractOrderingField";
import { FIRST_PAGE, TABLE_ACTION_SORT } from "constants/CommonConstants";

export function useCommonTableHandles(defaultOrdering = "") {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(FIRST_PAGE);
  const [ordering, setOrdering] = useState(defaultOrdering);

  const handleSearch = (value) => {
    setPage(FIRST_PAGE);
    setSearch(value);
  };

  const resetSearch = () => {
    setPage(FIRST_PAGE);
    setSearch("");
  };

  const handleTableChange = (pagination, _, sorter, extra) => {
    const ordering = extractOrderingFields(sorter, extra);

    if (extra?.action == TABLE_ACTION_SORT) {
      setOrdering(ordering);
    } else {
      setPage(pagination?.current);
    }
  };

  return {
    page,
    search,
    setPage,
    ordering,
    resetSearch,
    handleSearch,
    handleTableChange,
  };
}
