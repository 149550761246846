export const DEFAULT_ORDER_FIELD = "created_at";
export const DEFAULT_ORDERING = "-created_at";
export const NUMBER_PER_PAGE = 5;
export const FIRST_PAGE = 1;
export const DEFAULT_ORDER_FIELD_LOYALTY_USERS = "date_joined";
export const DEFAULT_ORDERING_LOYALTY_USERS = "-date_joined";

export const LOYALTY = "LOYALTY";
export const MEMBERSHIPS = "MEMBERSHIPS";

export const LOYALTY_TABS = {
  [LOYALTY]: "LOYALTY",
  [MEMBERSHIPS]: "MEMBERSHIPS",
};
