import { defineMessages } from "react-intl";

export const scope = "loyaltyCartPage";

export default defineMessages({
  membership: {
    id: `${scope}.membership`,
    defaultMessage: "Membership",
  },
  billingSchedule: {
    id: `${scope}.billingSchedule`,
    defaultMessage: "Billing Schedule",
  },
  checkout: {
    id: `${scope}.checkout`,
    defaultMessage: "Checkout",
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: "Name",
  },
  noItemsInCart: {
    id: `${scope}.noItemsInCart`,
    defaultMessage: "Currently, you have no items in your cart.",
  },
  useExistingCard: {
    id: `${scope}.useExistingCard`,
    defaultMessage: "Use Existing Card",
  },
  addNewCard: {
    id: `${scope}.addNewCard`,
    defaultMessage: "Add New Card",
  },
  cardDescription: {
    id: `${scope}.cardDescription`,
    defaultMessage: "Select a card to use for your membership",
  },
  cardDescription3: {
    id: `${scope}.cardDescription3`,
    defaultMessage: "If you don't have a card on file, you can add one here.",
  },
  successfullSubscription: {
    id: `${scope}.successfullSubscription`,
    defaultMessage: "Subscription created successfully",
  },
  previous: {
    id: `${scope}.previous`,
    defaultMessage: "Previous",
  },
});
