import React from "react";
import { useIntl } from "react-intl";
import { getAsCurrency } from "helpers/getAsCurrency";
import { CYCLES_NUMBER } from "constants/MembershipConstants";
import messages from "./translations";
import { cadencePluralOrSingular } from "views/app-views/MembershipsPage/MembershipCard/variationsCard";

export const BillingPhase = ({ phase, index }) => {
  const { formatMessage } = useIntl();

  const isFirstPhase = index === 0;
  const hasMultiplePhases = phase?.phases?.length > 1;
  const isSinglePhase = !hasMultiplePhases;
  const isFreeTrial =
    isFirstPhase && Number.parseFloat(phase.price_amount) === 0;
  const isPaidFirstPhase =
    isFirstPhase && Number.parseFloat(phase.price_amount) > 0;

  const renderSinglePhase = () => (
    <div className="d-flex justify-content-between">
      <span>
        {phase.period
          ? formatMessage(messages.oneTimeWithPeriod, {
              period: phase.period,
              cadence: phase.cadence?.toLowerCase(),
              cadenceNumber:
                CYCLES_NUMBER[cadencePluralOrSingular(phase.period)][
                  phase.cadence
                ]?.toLowerCase(),
            })
          : formatMessage(messages.oneTime, {
              cadence: phase?.cadence?.toLowerCase(),
            })}
      </span>
      <span>{getAsCurrency(phase.price_amount)}</span>
    </div>
  );

  const renderFreeTrialPhase = () => (
    <div className="d-flex justify-content-between">
      <span>
        {formatMessage(messages.freeTrialPeriod, {
          period: phase.period,
          cadence: phase.cadence?.toLowerCase(),
          cadenceNumber:
            CYCLES_NUMBER[cadencePluralOrSingular(phase.period)][
              phase.cadence
            ]?.toLowerCase(),
        })}
      </span>
      <span>{getAsCurrency(0)}</span>
    </div>
  );

  const renderPaidFirstPhase = () => (
    <div className="d-flex justify-content-between">
      <span>
        {phase.period
          ? formatMessage(messages.initialPaymentWithPeriod, {
              period: phase.period,
              cadence: phase.cadence?.toLowerCase(),
            })
          : formatMessage(messages.initialPayment)}
      </span>
      <span>{getAsCurrency(phase.price_amount)}</span>
    </div>
  );

  const renderPhases = () => {
    if (isFreeTrial) {
      return renderFreeTrialPhase();
    }
    if (isSinglePhase) {
      return renderSinglePhase();
    }
    if (isPaidFirstPhase) {
      return renderPaidFirstPhase();
    }
  };

  return (
    <div key={index} className="mb-2">
      {renderPhases()}
    </div>
  );
};

const PurchaseSummary = ({ selectedMembership, selectedVariation }) => {
  const { formatMessage } = useIntl();

  const getBillingSchedule = () => {
    if (!selectedVariation?.phases) return null;

    return selectedVariation.phases.map((phase, index) => (
      <BillingPhase key={index} phase={phase} index={index} />
    ));
  };

  return (
    <>
      <div className="my-5">
        <div className="border rounded p-4">
          <h2 className="mb-4">{formatMessage(messages.membership)}</h2>

          {selectedMembership && (
            <div>
              <div className="d-flex justify-content-between mb-3">
                <span className="font-weight-bold">
                  {selectedMembership.name}
                </span>
              </div>

              {getBillingSchedule()}

              <hr className="my-4" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PurchaseSummary;
