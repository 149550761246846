import React, { useEffect } from "react";
import Loader from "shared/LoaderComponent";
import { Card, Row, Col, Empty } from "antd";
import { DATE_FORMAT_MM_DD_YYYY_US } from "constants/DateConstant";
import { useGetLoyaltySubscriptions } from "queries/memberships";
import { ACTIVITY } from "constants/MembershipConstants";
import { CREATED_AT_SORT } from "constants/CommonConstants";
import { useIntl } from "react-intl";
import { formatDate } from "helpers/date";
import translations from "../translations";
import { useInView } from "react-intersection-observer";

const PastSubscriptionCard = ({ subscription }) => {
  const { formatMessage } = useIntl();
  const variation = subscription?.membership?.variations?.find(
    (v) => v.id === subscription.membership_variation
  );

  const card = subscription?.cards?.[0];

  return (
    <Card style={{ marginBottom: 16 }}>
      <div>
        <div className="d-flex justify-content-end">
          <p
            className="mb-0 border rounded p-1 px-2"
            style={{ backgroundColor: "#FFBA42" }}
          >
            {subscription.status}
          </p>
        </div>
        <p>
          <span>{formatMessage(translations.purchased)}</span>
          <span className="font-weight-bold ml-1">
            {formatDate(subscription.created_at, DATE_FORMAT_MM_DD_YYYY_US)}
          </span>
        </p>
        <h3>{subscription.membership?.name}</h3>
        <p className="font-size-md">
          {formatMessage(translations.variation, {
            variation: variation?.name,
          })}
        </p>
      </div>
      <div>
        <Row gutter={16}>
          <Col span={12}>
            <p className="mb-0">{card?.billing_name}</p>
          </Col>
          <Col span={12} align="right">
            <Row gutter={16}>
              <Col span={24}>
                <p className="mb-0 font-weight-bold">
                  {card?.brand} **** {card?.last_digits}
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <p className="mb-0">
                  <span>{formatMessage(translations.expires)}</span>
                  <span className="font-weight-bold ml-1">
                    {card?.expire_month}/{card?.expire_year}
                  </span>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

const InactiveTab = ({ contactId }) => {
  const { ref, inView } = useInView();

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetLoyaltySubscriptions({
      contact: contactId,
      status: ACTIVITY.CANCELED?.toUpperCase(),
      ordering: CREATED_AT_SORT,
    });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const pastSubscriptions =
    data?.pages?.flatMap((page) => page.data.results) || [];

  const hasNoData =
    !isLoading && (!pastSubscriptions || pastSubscriptions?.length === 0);

  return (
    <div>
      <Loader isLoading={isLoading} />
      {hasNoData && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      {!isLoading && (
        <>
          {pastSubscriptions?.map((subscription) => (
            <PastSubscriptionCard
              key={subscription.id}
              subscription={subscription}
            />
          ))}
          <div ref={ref}>
            {isFetchingNextPage && <Loader isLoading={true} />}
          </div>
        </>
      )}
    </div>
  );
};

export default InactiveTab;
