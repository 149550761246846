import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makeSelectCart } from "redux/selectors/Cart";
import { Button, Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { ROUTES } from "routes";
import { BillingPhase } from "views/app-views/SingleContactPage/MembershipsTab/purchaseSummary";
import messages from "./translations";
import { setCart } from "redux/actions/Cart";
import { format } from "util";
import { setLocalStorageItem } from "utils/localStorage";

const CartPage = () => {
  const { formatMessage } = useIntl();
  const params = useParams();
  const cart = useSelector(makeSelectCart());
  const history = useHistory();
  const dispatch = useDispatch();

  const variation = cart?.[0]?.variation;
  const membership = cart?.[0]?.membership;

  const handleCheckout = () => {
    history.push(format(ROUTES.LOYALTY_CHECKOUT_CONTACT, params?.contact_id));
  };

  const getBillingSchedule = () => {
    if (!variation?.phases) return null;

    return variation.phases.map((phase, index) => (
      <BillingPhase key={index} phase={phase} index={index} />
    ));
  };

  const handleRemoveItem = () => {
    dispatch(setCart([]));
    setLocalStorageItem("cart", []);
  };

  return (
    <div className="w-100 d-flex justify-content-center">
      <>
        {membership ? (
          <div className="my-5" style={{ minWidth: "400px" }}>
            <div className="border rounded p-4">
              <Row align={"middle"} className="mb-3">
                <Col span={18}>
                  <p className="font-weight-bold font-size-lg mb-0">
                    {membership?.name}
                  </p>
                </Col>
                <Col span={6} align={"right"}>
                  <Button
                    icon={<CloseOutlined />}
                    onClick={handleRemoveItem}
                  ></Button>
                </Col>
              </Row>

              <div>
                {getBillingSchedule()}
                <hr className="my-4" />
              </div>

              <Button className="w-100 checkout-btn" onClick={handleCheckout}>
                {formatMessage(messages.checkout)}
              </Button>
            </div>
          </div>
        ) : (
          <p className="text-center my-5 font-size-md border rounded p-3">
            {formatMessage(messages.noItemsInCart)}
          </p>
        )}
      </>
    </div>
  );
};

export default CartPage;
