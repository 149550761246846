import ApiService from "./ApiService";
import { stringify } from "querystring";

const ENDPOINTS = {
  FEATURE_CONTROL: "/feature-control",
};

class FeatureControlService extends ApiService {
  getFeatures = (payload) => {
    return this.apiClient.get(
      `${ENDPOINTS.FEATURE_CONTROL}/?${stringify(payload)}`
    );
  };
}

export default new FeatureControlService();
