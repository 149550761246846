import React, { useState, useEffect } from "react";
import { Row, Col, Radio } from "antd";
import { useIntl } from "react-intl";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { useCreateSubscription, useGetContactCards } from "queries/memberships";
import { setCart } from "redux/actions/Cart";
import { makeSelectCart } from "redux/selectors/Cart";
import { makeSelectCurrentUser } from "redux/selectors/Users";
import { useGetMerchant } from "queries/merchants";
import { EXISTING, NEW, BACK } from "constants/MembershipConstants";
import { getLocalStorageItem, setLocalStorageItem } from "utils/localStorage";
import ExistingCards from "views/app-views/SingleContactPage/MembershipsTab/creditCards";
import { format } from "util";
import { ROUTES } from "routes";
import messages from "./translations";
import Loader from "shared/LoaderComponent";

const CheckoutPage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const user = useSelector(makeSelectCurrentUser());
  const cart = useSelector(makeSelectCart());
  const organizationId = getLocalStorageItem("selectedOrganization");
  const [showAddNewCard, setShowAddNewCard] = useState(EXISTING);
  // eslint-disable-next-line no-unused-vars
  const [_, setSelectedCard] = useState(null);

  const subscribe = useCreateSubscription(
    formatMessage(messages.successfullSubscription)
  );

  const {
    data: response,
    isLoading: { contactCardsLoading },
  } = useGetContactCards({ contact_id: params?.contact_id });

  const { data: merchant } = useGetMerchant({
    organization_id: organizationId,
  });

  useEffect(() => {
    if (subscribe.isSuccess) {
      clearOutCart();
    }
  }, [subscribe.isSuccess]);

  useEffect(() => {
    if (response?.data?.length) {
      setShowAddNewCard(EXISTING);
    } else {
      setShowAddNewCard(NEW);
    }
  }, [response?.data]);

  const handleSubmit = (token, verifiedBuyer) => {
    const payload = {
      card: {
        brand: token?.details?.card?.brand,
        expire_month: token?.details?.card?.expMonth,
        expire_year: token?.details?.card?.expYear,
        last_digits: token?.details?.card?.last4,
        billing_name: `${user?.name} ${user?.last_name}`,
        postal_code: token?.details?.billing?.postalCode,
        token: token?.token,
      },
      organization: organizationId,
      membership_variation: cart?.[0]?.variation?.id,
      contact: params?.contact_id,
    };
    subscribe.mutate(payload);
  };

  const handleAddNewCard = ({ target: { value } }) => {
    if (value === BACK) {
      history.push(format(ROUTES.LOYALTY_CART_CONTACT, params?.contact_id));
    }
    setShowAddNewCard(value);
  };

  const options = [
    {
      label: formatMessage(messages.previous),
      value: BACK,
    },
    {
      label: formatMessage(messages.useExistingCard),
      value: EXISTING,
      disabled: !response?.data?.length,
    },
    { label: formatMessage(messages.addNewCard), value: NEW },
  ];

  const clearOutCart = () => {
    dispatch(setCart([]));
    setLocalStorageItem("cart", []);
  };

  return (
    <div className="w-100 d-flex justify-content-center">
      <div style={{ minWidth: "400px" }}>
        <Row gutter={[16, 16]} className="my-3">
          <Loader isLoading={subscribe.isLoading} />
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24} align={"middle"}>
            <Radio.Group
              options={options}
              onChange={handleAddNewCard}
              value={showAddNewCard}
              optionType="button"
            />
            {showAddNewCard == EXISTING && (
              <ExistingCards
                contact={{
                  id: params?.contact_id,
                  organization: { id: organizationId },
                }}
                justifyCenter={true}
                cards={response?.data}
                selectedVariation={cart?.[0]?.variation}
                setSelectedCard={setSelectedCard}
                handleNextStep={clearOutCart}
              />
            )}
            <Loader isLoading={contactCardsLoading} />
            {showAddNewCard == NEW && (
              <>
                <div className={`${response?.data?.length ? "" : "mt-5"}`}>
                  <h3 className="my-3">
                    {formatMessage(messages.cardDescription)}
                  </h3>
                  <p className="my-2">
                    {formatMessage(messages.cardDescription3)}
                  </p>
                </div>
                {merchant?.data?.location_id && (
                  <div className="mt-5">
                    <PaymentForm
                      applicationId={process.env.REACT_APP_SQUARE_CLIENT_ID}
                      locationId={merchant?.data?.location_id}
                      cardTokenizeResponseReceived={handleSubmit}
                    >
                      <CreditCard></CreditCard>
                    </PaymentForm>
                  </div>
                )}
              </>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CheckoutPage;
