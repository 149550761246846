import { format } from "util";
import { stringify } from "querystring";
import ApiService from "./ApiService";

const ENDPOINTS = {
  MEMBERSHIPS: "memberships/",
  MEMBERSHIP: "memberships/%s/",
  VARIATIONS: "memberships/%s/variations/",
  VARIATION: "memberships/%s/variations/%s/",
  SUBSCRIPTION: "contact-memberships/",
  CARDS: "contact-memberships/cards/",
  SUBSCRIPTIONS: "contact-memberships/",
  INVOICES: "contact-invoices/",
};

class MembershipsService extends ApiService {
  createMembership = (payload) => {
    return this.apiClient.post(ENDPOINTS.MEMBERSHIPS, payload);
  };

  getMemberships = (payload) => {
    return this.apiClient.get(`${ENDPOINTS.MEMBERSHIPS}?${stringify(payload)}`);
  };

  disableMembership = (mebershipId) => {
    return this.apiClient.delete(format(ENDPOINTS.MEMBERSHIP, mebershipId));
  };

  patchMembership = (data) => {
    const { membershipId } = data;
    return this.apiClient.patch(
      format(ENDPOINTS.MEMBERSHIP, membershipId),
      data?.payload
    );
  };

  patchVariation = (data) => {
    const { membershipId, variationId } = data;
    return this.apiClient.patch(
      format(ENDPOINTS.VARIATION, membershipId, variationId),
      data?.payload
    );
  };

  createVariation = (payload) => {
    const { membershipId } = payload;
    return this.apiClient.post(
      format(ENDPOINTS.VARIATIONS, membershipId),
      payload?.data
    );
  };

  disableVariation = (payload) => {
    const { membershipId, variationId } = payload;
    return this.apiClient.delete(
      format(ENDPOINTS.VARIATION, membershipId, variationId)
    );
  };

  createSubscription = (payload) => {
    return this.apiClient.post(ENDPOINTS.SUBSCRIPTION, payload);
  };

  getContactCards = (payload) => {
    return this.apiClient.get(`${ENDPOINTS.CARDS}?${stringify(payload)}`);
  };

  getSubscriptions = (payload) => {
    return this.apiClient.get(
      `${ENDPOINTS.SUBSCRIPTIONS}?${stringify(payload)}`
    );
  };

  getInvoices = (payload) => {
    return this.apiClient.get(`${ENDPOINTS.INVOICES}?${stringify(payload)}`);
  };

  cancelSubscription = (payload) => {
    return this.apiClient.delete(`${ENDPOINTS.SUBSCRIPTION}${payload?.id}/`);
  };
}

export default new MembershipsService();
