import { ACTIVITY } from "constants/MembershipConstants";
import Loader from "shared/LoaderComponent";
import OfferMembershipCard from "../offerMembershipCard";
import { CREATED_AT_SORT } from "constants/CommonConstants";
import { useGetLoyaltyMemberships } from "queries/memberships";
import { useCommonTableHandles } from "hooks/useCommonTableHandles";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

const OurOfferTab = ({ organization, setSelectedMembership }) => {
  const { ordering } = useCommonTableHandles(CREATED_AT_SORT);
  const { ref, inView } = useInView();

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetLoyaltyMemberships({
      organization,
      ordering,
      status: ACTIVITY.ACTIVE,
    });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const memberships = data?.pages?.flatMap((page) => page.data.results) || [];

  return (
    <div>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <>
          {memberships.map((membership) => (
            <OfferMembershipCard
              key={membership.id}
              membership={membership}
              setSelectedMembership={setSelectedMembership}
            />
          ))}
          <div ref={ref}>
            {isFetchingNextPage && <Loader isLoading={true} />}
          </div>
        </>
      )}
    </div>
  );
};

export default OurOfferTab;
