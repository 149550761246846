import {
  SET_CART,
  GET_CART,
  SET_CONTACT_ID,
  GET_CONTACT_ID,
} from "redux/constants/Cart";

export const setCart = (payload) => ({
  type: SET_CART,
  payload,
});

export const getCart = () => ({
  type: GET_CART,
});

export const setCartContactId = (payload) => ({
  type: SET_CONTACT_ID,
  payload,
});

export const getCartContactId = () => ({
  type: GET_CONTACT_ID,
});
