import React, { useState, useEffect } from "react";
import { Badge } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { ROUTES } from "routes";
import { useSelector } from "react-redux";
import { format } from "util";
import { makeSelectCart, makeSelectContactId } from "redux/selectors/Cart";
import { getLocalStorageItem, setLocalStorageItem } from "utils/localStorage";

const Cart = () => {
  const contactId = useSelector(makeSelectContactId());
  const cart = useSelector(makeSelectCart());
  const [cartItems, setCartItems] = useState(cart?.length || 0);
  const history = useHistory();

  useEffect(() => {
    const LSCart = getLocalStorageItem("cart");
    if (!LSCart) {
      setLocalStorageItem("cart", []);
    }
  }, []);

  useEffect(() => {
    setCartItems(cart.length);
  }, [cart]);

  const handleCartClick = () => {
    history.push(format(ROUTES.LOYALTY_CART_CONTACT, contactId));
  };

  return (
    <div
      style={{ cursor: "pointer" }}
      className="d-flex justify-content-end mr-3 mt-3"
      onClick={handleCartClick}
    >
      <Badge count={cartItems} overflowCount={99}>
        <ShoppingCartOutlined style={{ fontSize: "24px" }} />
      </Badge>
    </div>
  );
};

export default Cart;
