import React from "react";

const Loader = ({ isLoading }) =>
  isLoading ? (
    <div id="loader-wrapper">
      <div id="loader"></div>
    </div>
  ) : null;

export default Loader;
