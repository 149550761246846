import { useQuery } from "react-query";
import MerchantService from "services/MerchantService";

const GET_MERCHANT = "GET_MERCHANT";

const useGetMerchant = (payload) => {
  return useQuery({
    queryKey: [GET_MERCHANT, payload],
    queryFn: () => MerchantService.getMerchant(payload),
  });
};

export { useGetMerchant };
