import { createSelector } from "reselect";
import reducers from "../reducers";

const selectFeatureControlDomain = (state) => state.featureControl || reducers;

const makeSelectControlledFeatures = () =>
  createSelector(
    selectFeatureControlDomain,
    (substate) => substate.controlledFeatures
  );

export { makeSelectControlledFeatures };
