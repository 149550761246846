// probaj da testiras da li mozes da dobijes contact membership-e po drugom contactID-u od onog sto si ulogovan
export const getLowestPrice = (variations) => {
  const phases = [];
  variations?.forEach((variation) => {
    variation.phases.forEach((phase) => {
      if (Number.parseFloat(phase?.price_amount) != 0.0) {
        phases.push(Number.parseFloat(phase.price_amount));
      }
    });
  });
  const lowestPrice = Math.min(...phases);
  return lowestPrice;
};
