import { all } from "redux-saga/effects";
import Auth from "./Auth";
import Contact from "./Contact";
import ContactSettings from "./ContactSettings";
import Funnel from "./Funnel";
import Appointment from "./Appointment";
import Organization from "./Organization";
import Activity from "./Activity";
import HtmlBuilder from "./HtmlBuilder";
import Campaign from "./Campaign";
import SmsBuilder from "./SmsBuilder";
import Event from "./Event";
import Conversation from "./Conversation";
import WebPage from "./WebPage";
import FormBuilder from "./FormBuilder";
import Payment from "./Payment";
import NotificationSettings from "./NotificationSettings";
import ChatWidget from "./ChatWidget";
import Treatment from "./Treatment";
import Doctors from "./Doctors";
import Reviews from "./Reviews";
import Dashboard from "./Dashboard";
import Notes from "./Notes";
import Awards from "./Awards";
import LoyaltyUsers from "./LoyaltyUsers";
import SocialMediaPermissions from "./SocialMediaPermissions";
import Trigger from "./Trigger";
import PaymentIntegration from "./PaymentIntegrations";
import Documents from "./Documents";
import Pictures from "./Pictures";
import Pipelines from "./Pipelines";
import TwilioBrandRegistration from "./TwilioBrandRegostration";
import Reporting from "./Reporting";
import FeatureControl from "./FeatureControl";
export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Awards(),
    Contact(),
    ContactSettings(),
    Funnel(),
    Appointment(),
    Organization(),
    Activity(),
    HtmlBuilder(),
    Campaign(),
    SmsBuilder(),
    Event(),
    Conversation(),
    WebPage(),
    FormBuilder(),
    Payment(),
    NotificationSettings(),
    ChatWidget(),
    Treatment(),
    Doctors(),
    Reviews(),
    Dashboard(),
    Notes(),
    LoyaltyUsers(),
    SocialMediaPermissions(),
    Trigger(),
    PaymentIntegration(),
    Documents(),
    TwilioBrandRegistration(),
    Pictures(),
    Pipelines(),
    Reporting(),
    FeatureControl(),
  ]);
}
