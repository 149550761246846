import { defineMessages } from "react-intl";

export const scope = "loyaltyMembershipsPage";

export default defineMessages({
  status: {
    id: `${scope}.status`,
    defaultMessage: "Status",
  },
  fromPrice: {
    id: `${scope}.fromPrice`,
    defaultMessage: "From {price}",
  },
  subscribe: {
    id: `${scope}.subscribe`,
    defaultMessage: "Subscribe",
  },
  browseOtherPlans: {
    id: `${scope}.browseOtherPlans`,
    defaultMessage: "Browse More",
  },
  addToCart: {
    id: `${scope}.addToCart`,
    defaultMessage: "Add to Cart",
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: "Active",
  },
  inactive: {
    id: `${scope}.inactive`,
    defaultMessage: "Inactive",
  },
  ourOffer: {
    id: `${scope}.ourOffer`,
    defaultMessage: "Our Offer",
  },
  purchased: {
    id: `${scope}.purchased`,
    defaultMessage: "Purchased on: ",
  },
  details: {
    id: `${scope}.details`,
    defaultMessage: "Details",
  },
  cancelConfirmTitle: {
    id: "app.membership.cancel.confirm.title",
    defaultMessage: "Cancel Membership",
  },
  cancelConfirmContent: {
    id: "app.membership.cancel.confirm.content",
    defaultMessage:
      "Are you sure you want to cancel this membership? This action cannot be undone.",
  },
  confirm: {
    id: "app.membership.confirm",
    defaultMessage: "Confirm",
  },
  benefits: {
    id: "app.membership.benefits",
    defaultMessage: "Benefits",
  },
  paymentDetails: {
    id: "app.membership.payment.details",
    defaultMessage: "Payment Details",
  },
  backBtn: {
    id: "app.membership.backBtn",
    defaultMessage: "Back",
  },
  cancelBtn: {
    id: "app.membership.cancelBtn",
    defaultMessage: "Cancel",
  },
  cancelSuccess: {
    id: "app.membership.cancel.success",
    defaultMessage: "Membership cancelled successfully",
  },
  variation: {
    id: `${scope}.variation`,
    defaultMessage: "Variation: {variation}",
  },
  expires: {
    id: `${scope}.expires`,
    defaultMessage: "Expires:",
  },
  invoices: {
    id: `${scope}.invoices`,
    defaultMessage: "Invoices",
  },
  invoiceNumber: {
    id: "memberships.invoice.number",
    defaultMessage: "Invoice Number",
  },
  invoicesDate: {
    id: "memberships.invoice.date",
    defaultMessage: "Date",
  },
  invoicesAmount: {
    id: "memberships.invoice.amount",
    defaultMessage: "Amount",
  },
  invoicesStatus: {
    id: "memberships.invoice.status",
    defaultMessage: "Status",
  },
});
