class FormatMessageSingleton {
  constructor() {
    if (FormatMessageSingleton.instance) {
      return FormatMessageSingleton.instance;
    }
    this.formatMessageFn = null;
    FormatMessageSingleton.instance = this;
  }

  setFormatMessage(formatMessage) {
    this.formatMessageFn = formatMessage;
  }

  getFormatMessage() {
    if (!this.formatMessageFn) {
      throw new Error("FormatMessage not initialized");
    }
    return this.formatMessageFn;
  }
}

export const formatMessageSingleton = new FormatMessageSingleton();
