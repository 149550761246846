export const DAILY = "DAILY";
export const WEEKLY = "WEEKLY";
export const MONTHLY = "MONTHLY";

export const CYCLES = {
  [DAILY]: DAILY,
  [WEEKLY]: WEEKLY,
  [MONTHLY]: MONTHLY,
};

export const CYCLES_NUMBER = {
  plural: {
    [DAILY]: "DAYS",
    [WEEKLY]: "WEEKS",
    [MONTHLY]: "MONTHS",
  },
  singular: {
    [DAILY]: "DAY",
    [WEEKLY]: "WEEK",
    [MONTHLY]: "MONTH",
  },
};

export const NEVER = "NEVER";
export const CUSTOM = "CUSTOM";

export const PLAN_END = {
  [NEVER]: NEVER,
  [CUSTOM]: CUSTOM,
};

export const ACTIVE = "ACTIVE";
export const DISCONTINUED = "DISCONTINUED";
export const PENDING = "PENDING";
export const STOPED = "CANCELED";
export const PAUSED = "PAUSED";

export const ACTIVITY = {
  [ACTIVE]: "Active",
  [DISCONTINUED]: "Disabled",
  [PENDING]: "Pending",
  [STOPED]: "Canceled",
};

export const PLURAL = "plural";
export const SINGULAR = "singular";

export const VARIATIONS_MAX_COUNT = 3;
export const VARIATIONS_MIN_COUNT = 1;

export const INITIAL_STEP = 0;
export const PAYMENT_INTEGRATIONS_KEY = "?key=8";

export const EXISTING = "EXISTING";
export const NEW = "NEW";
export const BACK = "BACK";

export const OUR_OFFER = "OUR_OFFER";
export const INACTIVE = "INACTIVE";
export const INVOICES = "INVOICES";

export const LOYALTY_TABS = {
  [OUR_OFFER]: "0",
  [ACTIVE]: "1",
  [INACTIVE]: "2",
  [INVOICES]: "3",
};

export const SESSION = "SESSION";
export const MIN_SESSIONS = 1;
export const MAX_SESSIONS = 10;

export const NAME_MAX_CHAR = 255;

export const MAX_PERCENTAGE = 100;
export const MAX_FIXED = 1000;

export const BENEFIT_ITEM_1 = "Benefit Item 1";

export const MIN_PRICE = 1;
export const MAX_PRICE = 1000000;

export const MIN_BILLING_CYCLES = 1;
export const MAX_BILLING_CYCLES = 100;

export const MIN_FREE_TRIAL = 0;
export const MAX_FREE_TRIAL = 10;

export const MODAL_BENEFITS = "benefits";
export const MODAL_DETAILS = "details";
export const DISCOUNT = "DISCOUNT";

export const ALL_SERVICES = "allServices";
export const ALL_PRODUCTS = "allProducts";
export const CUSTOM_PRICE = "CUSTOM_PRICE";

export const PERCENTAGE = "PERCENT";
export const FIXED = "AMOUNT";

export const ALL_SERVICES_ID = "-1";
export const ALL_PRODUCTS_ID = "-2";

export const MAX_NEW_PRICE = 1000000;
export const MIN_NEW_PRICE = 1;

export const UNIT = "UNIT";
export const COUPON = "COUPON";
export const COUPON_BASE = "COUPON_BASE";
export const INCLUDED = "INCLUDED";

export const MIN_COUPON = 1;
export const MAX_COUPON = 1000;
export const COUPON_BASE_AMOUNT = 100;

export const MAX_UNITS = 1000;
export const MIN_UNITS = 1;

export const PRODUCT = "PRODUCTS";
export const SERVICE = "SERVICES";

export const CANCELED = "CANCELED";

export const CONTACT_MEMBERSHIP_STATUS = {
  [ACTIVE]: "ACTIVE",
  [PENDING]: "PENDING",
  [CANCELED]: "CANCELED",
};

export const ITEM_COUNT = 2;
export const DESCRIPTION_MIN_CHAR = 1;
export const DESCRIPTION_MAX_CHAR = 255;
