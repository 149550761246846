import {
  SIGNIN,
  AUTHENTICATED,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNUP,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  SEND_FORGOT_PASSWORD_EMAIL,
  SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
  SEND_FORGOT_PASSWORD_EMAIL_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  SIGNIN_ERROR,
  CREATE_PASSWORD,
  CREATE_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  GET_CURRENT_USER_DATA,
  GET_CURRENT_USER_DATA_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_INFO,
  VERIFICATION_SIGNIN,
  VERIFICATION_ERROR,
  SIGNIN_LOYATY_USER,
  SET_USER_SOUND_NOTIFICATION,
} from "../constants/Auth";

export const signIn = (payload, disableSubmit) => {
  return {
    type: SIGNIN,
    payload,
    disableSubmit,
  };
};

export const signInError = (payload) => {
  return {
    type: SIGNIN_ERROR,
    payload,
  };
};

export const authenticated = (token, isNewUser) => {
  return {
    type: AUTHENTICATED,
    token,
    isNewUser,
  };
};

export const signOut = () => {
  return {
    type: SIGNOUT,
  };
};

export const signOutSuccess = () => {
  return {
    type: SIGNOUT_SUCCESS,
  };
};

export const signUp = (user, disableSubmit) => {
  return {
    type: SIGNUP,
    payload: user,
    disableSubmit,
  };
};

export const signUpSuccess = (token) => {
  return {
    type: SIGNUP_SUCCESS,
    token,
  };
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_AUTH_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return {
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return {
    type: SHOW_LOADING,
  };
};

export const sendForgotPasswordEmail = (
  payload,
  redirectRoute,
  disableSubmit
) => {
  return {
    type: SEND_FORGOT_PASSWORD_EMAIL,
    payload,
    redirectRoute,
    disableSubmit,
  };
};

export const sendForgotPasswordEmailSuccess = (payload) => {
  return {
    type: SEND_FORGOT_PASSWORD_EMAIL_SUCCESS,
    payload,
  };
};

export const sendForgotPasswordEmailError = (payload) => {
  return {
    type: SEND_FORGOT_PASSWORD_EMAIL_ERROR,
    payload,
  };
};

export const resetPassword = (payload, messageSuccess, redirectRoute) => {
  return {
    type: RESET_PASSWORD,
    payload,
    messageSuccess,
    redirectRoute,
  };
};

export const resetPasswordError = (payload) => {
  return {
    type: RESET_PASSWORD_ERROR,
    payload,
  };
};

export const createPassword = (
  payload,
  messageSuccess,
  redirectRoute,
  disableSubmit
) => {
  return {
    type: CREATE_PASSWORD,
    payload,
    messageSuccess,
    redirectRoute,
    disableSubmit,
  };
};

export const createPasswordError = (payload) => {
  return {
    type: CREATE_PASSWORD_ERROR,
    payload,
  };
};

export const changePassword = (payload, messageSuccess, disableSubmit) => {
  return {
    type: CHANGE_PASSWORD,
    payload,
    messageSuccess,
    disableSubmit,
  };
};

export const changePasswordError = (payload) => {
  return {
    type: CHANGE_PASSWORD_ERROR,
    payload,
  };
};

export const getCurrentUserData = () => {
  return {
    type: GET_CURRENT_USER_DATA,
  };
};

export const getCurrentUserDataSuccess = (payload) => {
  return {
    type: GET_CURRENT_USER_DATA_SUCCESS,
    payload,
  };
};

export const updateUser = (payload, id, successMessage, disableSubmit) => {
  return {
    type: UPDATE_USER,
    payload,
    id,
    successMessage,
    disableSubmit,
  };
};

export const updateUserSuccess = (payload) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload,
  };
};

export const updateUserInfo = (payload) => {
  return {
    type: UPDATE_USER_INFO,
    payload,
  };
};

export const verificationLogin = (payload, disableSubmit) => {
  return {
    type: VERIFICATION_SIGNIN,
    payload,
    disableSubmit,
  };
};

export const verificationError = (payload) => {
  return {
    type: VERIFICATION_ERROR,
    payload,
  };
};

export const signInLoyaltyUser = (payload, disableSubmit) => {
  return {
    type: SIGNIN_LOYATY_USER,
    payload,
    disableSubmit,
  };
};

export const setUserSoundNotification = (payload) => {
  return {
    type: SET_USER_SOUND_NOTIFICATION,
    payload,
  };
};
