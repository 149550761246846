import ApiService from "./ApiService";
import { format } from "util";

const ENDPOINTS = {
  UPLOAD_IMAGE: "image/upload/",
  GET_EMAIL_TEMPLATES: "templates/?type=email&page=%s&perPage=%s",
  POST_TEMPLATE: "templates/",
  TEMPLATE_ITEM: "templates/%s/",
  TEST_EMAIL_TEMPLATE: "email/test-email/",
  GET_EMAIL_FORM_TEMPLATES: "default-email-template/",
  CREATE_EMAIL_FORM_TEMPLATE: "templates/create-email-template/",
  UPDATE_TEMPLATE_CONTENT: "templates/%s/update-content-template/",
  COPY_EMAIL_TEMPLATE: "templates/%s/copy-email-template/",
  GET_TEMPLATE_LIST_CONTENT:
    "templates/list-template-content/?page=%s&perPage=%s&type=email",
  SEND_MASS_EMAIL: "email/send-mass-email/",
  EMAIL_OVERAL_STATS: "templates/%s/email-analytics/overall/",
  EMAIL_STATS: "templates/%s/email-analytics/?page=%s&perPage=%s",
  EMAIL_CONTACTS:
    "templates/%s/email-analytics/%s/contacts/?page=%s&perPage=%s&ordering=%s",
  MASS_CONTACTS: "templates/%s/mass-contacts/?page=%s&ordering=%s",
  CREATE_EMAIL_BUILDER_TEMPLATE: "templates/create-email-builder-template/",
};

class HtmlBuilderService extends ApiService {
  uploadImageService = async (data) => {
    return await this.apiClient.post(ENDPOINTS.UPLOAD_IMAGE, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  getAllTemplates = async ({ page, perPage }) => {
    return await this.apiClient.get(
      format(ENDPOINTS.GET_EMAIL_TEMPLATES, page, perPage)
    );
  };

  getTemplateContent = async ({ page, NUMBER_PER_PAGE }) => {
    return await this.apiClient.get(
      format(ENDPOINTS.GET_TEMPLATE_LIST_CONTENT, page, NUMBER_PER_PAGE)
    );
  };

  sendMassEmail = async (data) => {
    return await this.apiClient.post(ENDPOINTS.SEND_MASS_EMAIL, data);
  };

  getTemplateById = async (data) => {
    return await this.apiClient.get(format(ENDPOINTS.TEMPLATE_ITEM, data));
  };

  postNewTemplate = async (data) => {
    return await this.apiClient.post(ENDPOINTS.POST_TEMPLATE, data);
  };

  updateTemplate = async (data) => {
    return await this.apiClient.patch(
      format(ENDPOINTS.TEMPLATE_ITEM, data.id),
      data
    );
  };

  copyEmailTemplate = (payload) => {
    return this.apiClient.post(
      format(ENDPOINTS.COPY_EMAIL_TEMPLATE, payload.id),
      payload.data
    );
  };

  updateTemplateContent = (data) => {
    return this.apiClient.patch(
      format(ENDPOINTS.UPDATE_TEMPLATE_CONTENT, data.get("id")),
      data
    );
  };

  deleteTemplate = async (data) => {
    return await this.apiClient.delete(format(ENDPOINTS.TEMPLATE_ITEM, data));
  };

  testSendEmail = async (data) => {
    return await this.apiClient.post(ENDPOINTS.TEST_EMAIL_TEMPLATE, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  getEmailFormTemplates = () => {
    return this.apiClient.get(ENDPOINTS.GET_EMAIL_FORM_TEMPLATES);
  };

  createEmailFormTemplates = (data) => {
    return this.apiClient.post(ENDPOINTS.CREATE_EMAIL_FORM_TEMPLATE, data);
  };

  getOverallEmailStats = (data) => {
    return this.apiClient.get(format(ENDPOINTS.EMAIL_OVERAL_STATS, data));
  };

  getEmailStats = (templateId, page, perPage) => {
    return this.apiClient.get(
      format(ENDPOINTS.EMAIL_STATS, templateId, page, perPage)
    );
  };

  getEmailContacts = ({
    templateId,
    analyticsId,
    page,
    perPage,
    orderField,
  }) => {
    return this.apiClient.get(
      format(
        ENDPOINTS.EMAIL_CONTACTS,
        templateId,
        analyticsId,
        page,
        perPage,
        orderField
      )
    );
  };

  getMassEmailContacts = (templateId, page, order) => {
    return this.apiClient.get(
      format(ENDPOINTS.MASS_CONTACTS, templateId, page, order)
    );
  };

  createEmailBuilderTemplate = (data) => {
    return this.apiClient.post(ENDPOINTS.CREATE_EMAIL_BUILDER_TEMPLATE, data);
  };
}

export default new HtmlBuilderService();
