export const PAYMENT_STEPS = {
  FIRST: 0,
  SECOND: 1,
  THIRD: 2,
};

export const CREDIT_CARD_BRANDS_LIST = [
  "amex",
  "discover",
  "mastercard",
  "visa",
];

export const CREDIT_CARD_BRANDS = {
  AMERICAN_EXPRESS: "amex",
  DISCOVER: "discover",
  MASTERCARD: "mastercard",
  VISA: "visa",
};

export const NUMBER_PER_PAGE = 10;

export const PRICE_MONTHLY = "$1500";
export const PRICE_YEARLY = "$15,000";

export const PLAN_TYPES = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
};

export const INPUT_MAX_LENGHT = 255;

// eslint-disable-next-line
export const REGEX_ZIP = /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/;

export const MAX_CARD_NAME_LENGTH = 100;

export const ALLOWED_PAYMENT_COUNTRES = ["Canada", "United States"];
export const MAX_ZIP_CODE_LENGTH = 12;

// default setting id, setting is pull based on organization that made the request
export const PAYMENT_SETTING_ID = 1;
