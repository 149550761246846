/* eslint-disable */
import { defineMessages } from "react-intl";

export const scope = "memberships_tab";

export default defineMessages({
  searchMemberships: {
    id: `${scope}.search.memberships`,
    defaultMessage: "Search memberships",
  },
  subscribe: {
    id: `${scope}.subscribe`,
    defaultMessage: "Buy Membership",
  },
  noMemberships: {
    id: `${scope}.no.memberships`,
    defaultMessage: "No memberships found",
  },
  memberships: {
    id: `${scope}.memberships`,
    defaultMessage: "Memberships",
  },
  price: {
    id: `${scope}.price`,
    defaultMessage: "Price",
  },
  selectMembership: {
    id: `${scope}.select.membership`,
    defaultMessage: "Select Membership",
  },
  selectMembershipDescription: {
    id: `${scope}.select.membership.description`,
    defaultMessage: "Select the membership",
  },
  selectVariation: {
    id: `${scope}.select.variation`,
    defaultMessage: "Select Variation",
  },
  selectVariationDescription: {
    id: `${scope}.select.variation.description`,
    defaultMessage: "Select the variation you want to buy",
  },
  payment: {
    id: `${scope}.payment`,
    defaultMessage: "Payment",
  },
  paymentDescription: {
    id: `${scope}.payment.description`,
    defaultMessage: "Pay for the membership",
  },
  paymentSuccess: {
    id: `${scope}.payment.success`,
    defaultMessage: "Payment successful",
  },
  previous: {
    id: `${scope}.previous`,
    defaultMessage: "Go Back",
  },
  cardDescription: {
    id: `${scope}.card.description`,
    defaultMessage: "Add contacts card to pay for the membership",
  },
  cardDescription2: {
    id: `${scope}.card.description2`,
    defaultMessage: "You can use your existing card or add a new one",
  },
  cardDescription3: {
    id: `${scope}.card.description3`,
    defaultMessage:
      "Saving card will allow you to pay for memberships and other things faster",
  },
  paymentSuccess: {
    id: `${scope}.payment.success`,
    defaultMessage: "Payment successful",
  },
  startOver: {
    id: `${scope}.start.over`,
    defaultMessage: "Start over",
  },
  total: {
    id: `${scope}.total`,
    defaultMessage: "Total",
  },
  price: {
    id: `${scope}.price`,
    defaultMessage: "Price",
  },
  membership: {
    id: `${scope}.membership`,
    defaultMessage: "Membership",
  },
  billedNow: {
    id: `${scope}.billed.now`,
    defaultMessage: "Billed now",
  },
  freeTrial: {
    id: `${scope}.free.trial`,
    defaultMessage: "Free trial",
  },
  oneTimeWithPeriod: {
    id: `${scope}.one.time.with.period`,
    defaultMessage: "Billed {cadence} for {period} {cadenceNumber}",
  },
  oneTime: {
    id: `${scope}.one.time`,
    defaultMessage: "Billed {cadence}",
  },
  freeTrialPeriod: {
    id: `${scope}.free.trial.period`,
    defaultMessage: "Free trial for {period} {cadenceNumber}",
  },
  SquareNotConnected: {
    id: `${scope}.square.not.connected`,
    defaultMessage:
      "Square is not connected, go to this link to connect it: {link}",
  },
  link: {
    id: `${scope}.link`,
    defaultMessage: "here",
  },
  savedCards: {
    id: `${scope}.saved.cards`,
    defaultMessage: "Saved Cards",
  },
  useThisCard: {
    id: `${scope}.use.this.card`,
    defaultMessage: "Use this card",
  },
  expires: {
    id: `${scope}.expires`,
    defaultMessage: "Expires",
  },
  addNewCard: {
    id: `${scope}.add.new.card`,
    defaultMessage: "Add new card",
  },
  useExistingCard: {
    id: `${scope}.use.existing.card`,
    defaultMessage: "Use existing card",
  },
  successfullSubscription: {
    id: `${scope}.successfull.subscription`,
    defaultMessage: "Subscription successful",
  },
  invalidEmail: {
    id: `${scope}.invalid.email`,
    defaultMessage:
      "This contact has an invalid email address. Please update the email address before proceeding with membership purchase.",
  },
  invalidPhoneNumber: {
    id: `${scope}.invalid.phone.number`,
    defaultMessage:
      "This contact has an invalid phone number. Please update the phone number before proceeding with membership purchase.",
  },
  statusColumn: {
    id: `${scope}.status.column`,
    defaultMessage: "Status",
  },
  createdAtColumn: {
    id: `${scope}.created_at.column`,
    defaultMessage: "Created At",
  },
  plansColumn: {
    id: `${scope}.plans.column`,
    defaultMessage: "Plans",
  },
  priceColumn: {
    id: `${scope}.price.column`,
    defaultMessage: "Price",
  },
  searchPlaceholder: {
    id: `${scope}.search.placeholder`,
    defaultMessage: "Search memberships",
  },
  addMembership: {
    id: `${scope}.add.membership`,
    defaultMessage: "Add a plan",
  },
});
