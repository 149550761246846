import { createSelector } from "reselect";
import reducers from "../reducers";

const selectCartDomain = (state) => state.cart || reducers;

const makeSelectCart = () =>
  createSelector(selectCartDomain, (substate) => substate.cart);

const makeSelectContactId = () =>
  createSelector(selectCartDomain, (substate) => substate.contactId);

export { makeSelectCart, makeSelectContactId };
