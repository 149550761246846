export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const DASHBOARD_DATA_SELECT_OPTIONS = ["Revenue", "Leads"];
export const DASHBOARD_GRAPH_LEADS_COLOR = "#2E93fA";
export const DASHBOARD_GRAPH_REVENUE_COLOR = "#66DA26";
export const REVENUE_SERIES = 0;
export const NOT_APPLICABLE = "0";
export const PENDING_STATUS = "Pending";
export const DOLLAR_CURRENCY = "$";

export const SELECT_REVENUE = "REVENUE";
export const SELECT_LEADS = "LEADS";

export const REVENUE = "Revenue";
export const LEADS = "Leads";

export const DASHBOARD = {
  [SELECT_REVENUE]: REVENUE,
  [SELECT_LEADS]: LEADS,
};

export const DEFAULT_ORDER_FIELD = "-converted_lead_at";

export const ORDER_FIELDS = {
  converted_lead_at: "converted_lead_at",
  amount_spent: "amount_spent",
};
export const ASCEND_SORT = "ascend";
export const RESPONSE_WAIT_TIME = 10000;
export const SUMMARY_PAGE_SIZE = 5;

export const SUMMARY_TABS = {
  EMAILS: "emailContent",
  SMS: "phoneContent",
  CAMPAIGNS: "campaigns",
  TREATMENTS: "treatments",
  NOTES: "notes",
};

export const FIRST_PAGE = 1;
