import React, { useEffect } from "react";
import { Row, Col } from "antd";
import { useIntl } from "react-intl";
import { useCreateSubscription } from "queries/memberships";
import { CREDIT_CARD_BRANDS } from "constants/PaymentConstant";
import Amex from "assets/cards/Amex.svg";
import Discover from "assets/cards/Discover.svg";
import Mastercard from "assets/cards/Mastercard.svg";
import Visa from "assets/cards/Visa.svg";
import messages from "./translations";
import { Card } from "antd";
import Loader from "shared/LoaderComponent";

export const ExistingCard = ({
  card,
  contact,
  justifyCenter,
  handleNextStep,
  setSelectedCard,
  selectedVariation,
}) => {
  const { formatMessage } = useIntl();
  const subscribe = useCreateSubscription(
    formatMessage(messages.successfullSubscription)
  );
  const brand_img_map = {
    [CREDIT_CARD_BRANDS.AMERICAN_EXPRESS]: Amex,
    [CREDIT_CARD_BRANDS.DISCOVER]: Discover,
    [CREDIT_CARD_BRANDS.MASTERCARD]: Mastercard,
    [CREDIT_CARD_BRANDS.VISA]: Visa,
  };

  useEffect(() => {
    if (subscribe.isSuccess) {
      handleNextStep();
    }
  }, [subscribe.isSuccess]);

  const handleUseThisCard = () => {
    setSelectedCard(card);
    subscribe.mutate({
      card: card?.square_id,
      membership_variation: selectedVariation?.id,
      contact: contact?.id,
      organization: contact?.organization?.id,
    });
  };

  return (
    <Row justify={justifyCenter ? "center" : "start"}>
      <Loader isLoading={subscribe.isLoading} />
      <Col span={20}>
        <Card className="mb-3" hoverable onClick={handleUseThisCard}>
          <div className="d-flex flex-column w-100">
            <img
              alt={card?.brand}
              src={brand_img_map[card?.brand.toLowerCase()]}
              className="payment__card-image small my-1"
            />
            <div className="my-2 font-size-md">
              **** **** **** {card.last_digits}
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <div className="font-weight-bold font-size-md">
                {card.billing_name}
              </div>
              <div className="text-muted">
                {`${card.expire_month} / ${card.expire_year
                  ?.toString()
                  ?.slice(2, 4)}`}
              </div>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

const ExistingCards = ({
  cards,
  contact,
  justifyCenter,
  handleNextStep,
  setSelectedCard,
  selectedVariation,
}) => {
  if (!cards?.length) {
    return null;
  }

  return (
    <div className="my-4">
      {cards.map((card) => (
        <ExistingCard
          key={card.id}
          card={card}
          contact={contact}
          justifyCenter={justifyCenter}
          selectedVariation={selectedVariation}
          setSelectedCard={setSelectedCard}
          handleNextStep={handleNextStep}
        />
      ))}
    </div>
  );
};

export default ExistingCards;
