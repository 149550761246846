import { Card, Row, Col, Button, Modal } from "antd";
import { useIntl } from "react-intl";
import { DATE_FORMAT_MM_DD_YYYY_US } from "constants/DateConstant";
import { formatDate } from "helpers/date";
import { useCancelSubscription } from "queries/memberships";
import MembershipPlanDetails from "shared/MembershipPlanDetails";
import translations from "./translations";

const MembershipDetails = ({
  subscription,
  setSelectedDetails,
  setSelectedMembership,
}) => {
  const { formatMessage } = useIntl();
  const successMessage = formatMessage(translations.cancelSuccess);

  const handleBack = () => {
    setSelectedDetails(null);
    setSelectedMembership(null);
  };

  const { mutate: cancelMembership, isLoading } = useCancelSubscription(
    successMessage,
    handleBack
  );

  const variation = subscription?.membership?.variations?.find(
    (v) => v.id === subscription.membership_variation
  );

  const card = subscription?.cards?.[0];

  const handleCancel = () => {
    Modal.confirm({
      title: formatMessage(translations.cancelConfirmTitle),
      content: formatMessage(translations.cancelConfirmContent),
      okText: formatMessage(translations.confirm),
      cancelText: formatMessage(translations.cancelBtn),
      onOk: () => {
        cancelMembership(subscription);
      },
    });
  };

  return (
    <div className="d-flex justify-content-center">
      <Card style={{ minWidth: "400px" }}>
        <div className="mb-4">
          <p className="d-flex align-items-center">
            <span>{formatMessage(translations.purchased)}</span>
            <span className="font-weight-bold ml-1">
              {formatDate(subscription.created_at, DATE_FORMAT_MM_DD_YYYY_US)}
            </span>
          </p>
          <h2 className="font-weight-bold">{subscription.membership?.name}</h2>
          <p className="font-size-md">
            {formatMessage(translations.variation, {
              variation: variation?.name,
            })}
          </p>
        </div>

        <div className="mb-4">
          <h3>{formatMessage(translations.benefits)}</h3>
          <MembershipPlanDetails benefits={variation?.benefits} />
        </div>

        <div className="mb-4">
          <h3>{formatMessage(translations.paymentDetails)}</h3>
          <Row gutter={16}>
            <Col span={12}>
              <p className="mb-0">{card?.billing_name}</p>
            </Col>
            <Col span={12} align="right">
              <p className="mb-0 font-weight-bold">
                {card?.brand} **** {card?.last_digits}
              </p>
              <p className="mb-0">
                <span>{formatMessage(translations.expires)}</span>
                <span className="font-weight-bold ml-1">
                  {card?.expire_month}/{card?.expire_year}
                </span>
              </p>
            </Col>
          </Row>
        </div>

        <Row gutter={16} justify="end">
          <Col>
            <Button onClick={handleBack} className="mr-2">
              {formatMessage(translations.backBtn)}
            </Button>
          </Col>
          <Col>
            <Button danger onClick={handleCancel} loading={isLoading}>
              {formatMessage(translations.cancelBtn)}
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default MembershipDetails;
