import { Col, Row } from "antd";
import { getAsCurrency } from "helpers/getAsCurrency";
import { CYCLES_NUMBER } from "constants/MembershipConstants";
import { cadencePluralOrSingular } from "views/app-views/MembershipsPage/MembershipCard/variationsCard";

const OfferPhaseDescription = ({ phase }) => {
  const cadence =
    CYCLES_NUMBER[cadencePluralOrSingular(phase?.period)][phase?.cadence];

  const isFreeTrail = phase?.price_amount == 0;

  return (
    <Row gutter={10} align="middle">
      <Col offset={3} span={4}>
        <p className="font-weight-bold">{phase?.period}</p>
      </Col>
      <Col span={8}>
        <p>{isFreeTrail ? cadence : phase?.cadence}</p>
      </Col>
      <Col span={6} className="d-flex justify-content-end">
        <p className="font-weight-bold">{getAsCurrency(phase?.price_amount)}</p>
      </Col>
    </Row>
  );
};

export default OfferPhaseDescription;
