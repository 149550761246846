import React, { lazy, Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { PrivateRoute } from "shared/PrivateRoute";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <PrivateRoute
          section={"contacts"}
          path={`${APP_PREFIX_PATH}/contacts`}
          component={lazy(() => import(`./ContactsPage`))}
          exact
        />
        <PrivateRoute
          section={"contacts"}
          path={`${APP_PREFIX_PATH}/contacts/:id`}
          component={lazy(() => import(`./SingleContactPage`))}
          exact
        />
        <PrivateRoute
          section={"funnels"}
          path={`${APP_PREFIX_PATH}/funnels`}
          component={lazy(() => import(`./FunnelsPage`))}
        />
        <PrivateRoute
          section={"settings"}
          path={`${APP_PREFIX_PATH}/settings`}
          component={lazy(() => import(`./SettingsPage`))}
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/email-builder/form-type-builder/:id`}
          component={lazy(() =>
            import(`./HtmlBuilderPage/EditorPage/formTypeEditorPage.js`)
          )}
          exact
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/email-builder/templates/`}
          component={lazy(() => import(`./HtmlBuilderPage`))}
          exact
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/email-builder/template/:id/email/:emailId`}
          component={lazy(() =>
            import(`./HtmlBuilderPage/EditorPage/formTypeEditorPage.js`)
          )}
          exact
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/email-builder/:id`}
          component={lazy(() => import(`./HtmlBuilderPage/SingleTemplate`))}
          exact
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/forms`}
          component={lazy(() => import("./FormBuilderPage"))}
          exact
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/forms/builder/:id?`}
          component={lazy(() => import("./FormBuilderPage/FormBuilder"))}
          exact
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/chat-widget`}
          component={lazy(() => import("./ChatWidgetPage"))}
          exact
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/campaigns`}
          component={lazy(() => import(`./CampaignsPage`))}
          exact
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/sms-builder`}
          component={lazy(() => import(`./SmsBuilderPage`))}
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/campaigns/:id`}
          component={lazy(() => import(`./CampaignsPage/SingleCampaignPage`))}
          exact
        />
        <PrivateRoute
          section={"conversations"}
          path={`${APP_PREFIX_PATH}/conversations`}
          component={lazy(() => import(`./ConversationsPage`))}
          exact
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/landing-page`}
          component={lazy(() => import(`./LandingPage`))}
          exact
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/landing-page/templates`}
          component={lazy(() =>
            import(`./LandingPage/LandingPageTemplates/index`)
          )}
          exact
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/landing-page/:id`}
          component={lazy(() => import(`./LandingPage/SingleLandingPage`))}
          exact
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/landing-page-builder/:id`}
          component={lazy(() => import(`./LandingPage/LandingPageBuilder`))}
          exact
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/edit-profile`}
          component={lazy(() => import(`./../app-views/components/Profile`))}
          exact
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/edit-password`}
          component={lazy(() =>
            import(`./../app-views/components/ChangePassword`)
          )}
          exact
        />
        <PrivateRoute
          path={`${APP_PREFIX_PATH}/not-found`}
          component={lazy(() => import(`../auth-views/errors/error-page-1`))}
          exact
        />
        <PrivateRoute
          section={"my-practice"}
          path={`${APP_PREFIX_PATH}/practice-staff`}
          component={lazy(() => import(`./DoctorsPage`))}
          exact
        />
        <PrivateRoute
          section={"my-practice"}
          path={`${APP_PREFIX_PATH}/practice-staff/create`}
          component={lazy(() => import(`./DoctorsPage/CreateDoctorPage`))}
          exact
        />
        <PrivateRoute
          section={"my-practice"}
          path={`${APP_PREFIX_PATH}/practice-staff/:id`}
          component={lazy(() => import(`./DoctorsPage/SingleDoctorsPage`))}
          exact
        />
        <PrivateRoute
          section={"my-practice"}
          path={`${APP_PREFIX_PATH}/practice-info`}
          component={lazy(() => import(`./PracticeInfoPage`))}
          exact
        />
        <PrivateRoute
          section={"my-practice"}
          path={`${APP_PREFIX_PATH}/employee-calendar`}
          component={lazy(() => import(`./EmployeeCalendar`))}
          exact
        />
        <PrivateRoute
          section={"dashboard"}
          path={`${APP_PREFIX_PATH}/dashboard/`}
          component={lazy(() => import("./MainDashboard"))}
          exact
        />
        <PrivateRoute
          section={"dashboard"}
          path={`${APP_PREFIX_PATH}/dashboard-summary/`}
          component={lazy(() => import("./DashboardPage"))}
          exact
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/embed-code/`}
          component={lazy(() => import("./EmbedCodePage"))}
          exact
        />
        <PrivateRoute
          section={"loyalty-program"}
          path={`${APP_PREFIX_PATH}/awards/single-user/:id`}
          component={lazy(() => import("./AwardsPage/singleUserAwardsPage"))}
          exact
        />
        <PrivateRoute
          section={"loyalty-program"}
          path={`${APP_PREFIX_PATH}/awards/create-award/`}
          component={lazy(() => import("./AwardsPage/createAwardsPage"))}
          exact
        />
        <PrivateRoute
          section={"loyalty-program"}
          path={`${APP_PREFIX_PATH}/awards/`}
          component={lazy(() => import("./AwardsPage"))}
          exact
        />
        <PrivateRoute
          section={"my-practice"}
          path={`${APP_PREFIX_PATH}/documents/`}
          component={lazy(() => import("./Documents"))}
          exact
        />
        <PrivateRoute
          section={"loyalty-program"}
          path={`${APP_PREFIX_PATH}/loyalty-users/`}
          component={lazy(() => import("./LoyaltyUsersPage"))}
          exact
        />
        <PrivateRoute
          section={"loyalty-program"}
          path={`${APP_PREFIX_PATH}/awards/:id/`}
          component={lazy(() => import("./AwardsPage/singleAwardPage"))}
          exact
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/campaigns/contacts/:id`}
          component={lazy(() =>
            import("./CampaignsPage/SingleCampaignPage/ContactsOnCampaignPage")
          )}
          exact
        />
        <PrivateRoute
          section={"reporting"}
          path={`${APP_PREFIX_PATH}/transactions/`}
          component={lazy(() => import("./ReportingsPage/TransactionsPage"))}
          exact
        />
        <PrivateRoute
          section={"reporting"}
          path={`${APP_PREFIX_PATH}/marketing/`}
          component={lazy(() => import("./ReportingsPage/MarketingPage/"))}
          exact
        />
        <PrivateRoute
          section={"reporting"}
          path={`${APP_PREFIX_PATH}/loyalty-stats/`}
          component={lazy(() => import("./ReportingsPage/LoyaltyPage/"))}
          exact
        />
        <PrivateRoute
          section={"my-practice"}
          path={`${APP_PREFIX_PATH}/rooms/`}
          component={lazy(() => import("./Equipment"))}
          exact
        />
        <PrivateRoute
          section={"my-practice"}
          path={`${APP_PREFIX_PATH}/memberships/`}
          component={lazy(() => import("./MembershipsPage"))}
          exact
        />
        <PrivateRoute
          section={"scheduler"}
          path={`${APP_PREFIX_PATH}/scheduler`}
          component={lazy(() => import(`./CalendarPage/Scheduler`))}
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/virtual-consult/`}
          component={lazy(() => import(`./VirtualConsultPage/`))}
        />
        <PrivateRoute
          section={"marketing"}
          path={`${APP_PREFIX_PATH}/treatments`}
          component={lazy(() => import(`./ReportingsPage/TreatmentsPage`))}
          exact
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/contacts`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
