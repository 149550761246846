import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { ConnectedRouter } from "connected-react-router";
import { getCurrentUserData } from "redux/actions/Auth";
import { getControlledFeatures } from "redux/actions/FeatureControl";
import { THEME_CONFIG } from "./configs/AppConfig";
import history from "./utils/history";
import Views from "./views";
import { formatMessageSingleton } from "./utils/formatMessageSingleton";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(getCurrentUserData());
    dispatch(getControlledFeatures());
  }, []);

  useEffect(() => {
    formatMessageSingleton.setFormatMessage(formatMessage);
  }, [formatMessage]);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ThemeSwitcherProvider
          themeMap={themes}
          defaultTheme={THEME_CONFIG.currentTheme}
          insertionPoint="styles-insertion-point"
        >
          <ConnectedRouter history={history}>
            <Switch>
              <Route path="/" component={Views} />
            </Switch>
          </ConnectedRouter>
          <ReactQueryDevtools initialIsOpen={false} />
        </ThemeSwitcherProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
