import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Tabs } from "antd";
import OfferMembershipPurchase from "./offerMembershipPurchase";
import { getLocalStorageItem } from "utils/localStorage";
import translations from "./translations";
import OurOfferTab from "./tabs/offerTab";
import ActiveTab from "./tabs/activeTab";
import InactiveTab from "./tabs/inactiveTab";
import InvoicesTab from "./tabs/invoicesTab";
import MembershipDetails from "./membershipDetails";
import { makeSelectContactId } from "redux/selectors/Cart";
import { LOYALTY_TABS } from "constants/MembershipConstants";

const MembershipTabs = ({
  contactId,
  organization,
  setSelectedDetails,
  setSelectedMembership,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Tabs
      defaultActiveKey={LOYALTY_TABS.OUR_OFFER}
      className="loylaty-membership-tabs"
    >
      <Tabs.TabPane
        key={LOYALTY_TABS.OUR_OFFER}
        tab={formatMessage(translations.ourOffer)}
      >
        <OurOfferTab
          organization={organization}
          setSelectedMembership={setSelectedMembership}
        />
      </Tabs.TabPane>
      <Tabs.TabPane
        key={LOYALTY_TABS.ACTIVE}
        tab={formatMessage(translations.active)}
      >
        <ActiveTab
          contactId={contactId}
          setSelectedDetails={setSelectedDetails}
          setSelectedMembership={setSelectedMembership}
        />
      </Tabs.TabPane>
      <Tabs.TabPane
        key={LOYALTY_TABS.INACTIVE}
        tab={formatMessage(translations.inactive)}
      >
        <InactiveTab contactId={contactId} />
      </Tabs.TabPane>
      <Tabs.TabPane
        key={LOYALTY_TABS.INVOICES}
        tab={formatMessage(translations.invoices)}
      >
        <InvoicesTab contactId={contactId} />
      </Tabs.TabPane>
    </Tabs>
  );
};

const MembershipsPage = () => {
  const contactId = useSelector(makeSelectContactId());
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [selectedDetails, setSelectedDetails] = useState(null);
  const organization = getLocalStorageItem("selectedOrganization");

  const renderPageContent = () => {
    if (!selectedMembership) {
      return (
        <MembershipTabs
          contactId={contactId}
          organization={organization}
          setSelectedDetails={setSelectedDetails}
          setSelectedMembership={setSelectedMembership}
        />
      );
    }

    if (selectedMembership && !selectedDetails) {
      return (
        <OfferMembershipPurchase
          membership={selectedMembership}
          setSelectedMembership={setSelectedMembership}
        />
      );
    }

    if (selectedDetails) {
      return (
        <MembershipDetails
          subscription={selectedDetails}
          setSelectedDetails={setSelectedDetails}
          setSelectedMembership={setSelectedMembership}
        />
      );
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center w-100">
      {renderPageContent()}
    </div>
  );
};

export default MembershipsPage;
