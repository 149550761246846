import React, { useState } from "react";
import { Table } from "antd";
import Loader from "shared/LoaderComponent";
import { useGetLoyaltyInvoices } from "queries/memberships";
import { useIntl } from "react-intl";
import { formatDate } from "helpers/date";
import { DATE_MMM_Do_YYY } from "constants/DateConstant";
import translations from "../translations";
import { getAsCurrency } from "helpers/getAsCurrency";
import { FIRST_PAGE, PER_PAGE_10 } from "constants/CommonConstants";

const InvoicesTab = ({ contactId }) => {
  const { formatMessage } = useIntl();
  const [page, setPage] = useState(FIRST_PAGE);

  const { data: invoicesData, isLoading } = useGetLoyaltyInvoices({
    contact_id: contactId,
    page,
  });

  const columns = [
    {
      title: formatMessage(translations.invoiceNumber),
      dataIndex: "invoice_number",
      key: "invoice_number",
      render: (_, __, index) => `#${index + 1}`,
    },
    {
      title: formatMessage(translations.invoicesDate),
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => {
        const formatedDate = formatDate(date, DATE_MMM_Do_YYY);
        return <span>{formatedDate}</span>;
      },
    },
    {
      title: formatMessage(translations.invoicesAmount),
      dataIndex: "total",
      key: "total",
      render: (total) => `${getAsCurrency(total)}`,
    },
    {
      title: formatMessage(translations.invoicesStatus),
      dataIndex: "status",
      key: "status",
    },
  ];

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
  };

  if (isLoading) {
    return <Loader isLoading={true} />;
  }

  return (
    <div>
      <Table
        className="table-scroll pagination-style "
        columns={columns}
        dataSource={invoicesData?.data?.results}
        rowKey={(record) => record.id}
        pagination={{
          current: page,
          pageSize: PER_PAGE_10,
          total: invoicesData?.data?.count,
          showSizeChanger: false,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default InvoicesTab;
