import produce from "immer";
import { SET_CONTROLLED_FEATURES } from "redux/constants/FeatureControl";

const initialState = {
  controlledFeatures: [],
};

const featureControlReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    const { type, payload } = action;
    switch (type) {
      case SET_CONTROLLED_FEATURES:
        draft.controlledFeatures = payload;
        break;
      default:
        return state;
    }
  });

export default featureControlReducer;
