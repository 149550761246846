import { FIRST_PAGE } from "constants/ContactConstant";
import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from "react-query";
import { message } from "antd";
import MembershipsService from "services/MembershipsService";
import unpackAndShowErrors from "helpers/djangoErrorUnapacker";

export const GET_MEMBERSHIPS = "GET_MEMBERSHIPS";
export const GET_CONTACT_CARDS = "GET_CONTACT_CARDS";
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const GET_LOYALTY_SUBSCRIPTIONS = "GET_LOYALTY_SUBSCRIPTIONS";
export const GET_LOYALTY_MEMBERSHIPS = "GET_LOYALTY_MEMBERSHIPS";
export const GET_LOYALTY_INVOICES = "GET_LOYALTY_INVOICES";

const useCreateMembership = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) => {
      return MembershipsService.createMembership(payload);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(GET_MEMBERSHIPS, { page: FIRST_PAGE });
    },
    onError: ({ response: { data } }) => {
      console.log(data);
    },
  });
};

const useGetMemberships = (payload) =>
  useQuery({
    queryKey: [GET_MEMBERSHIPS, payload],
    queryFn: () => MembershipsService.getMemberships(payload),
  });

const useGetLoyaltyMemberships = (payload) =>
  useInfiniteQuery({
    queryKey: [GET_LOYALTY_MEMBERSHIPS, payload],
    queryFn: ({ pageParam = FIRST_PAGE }) =>
      MembershipsService.getMemberships({ ...payload, page: pageParam }),
    getNextPageParam: (lastPage) => {
      if (lastPage.data.next) {
        return lastPage.data.current + 1;
      }
      return undefined;
    },
    initialPageParam: FIRST_PAGE,
  });

const useDisableMembership = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (membershipId) => {
      return MembershipsService.disableMembership(membershipId);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(GET_MEMBERSHIPS, { page: FIRST_PAGE });
    },
    onError: ({ response: { data } }) => {
      console.log(data);
    },
  });
};

const usePatchMembership = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      return MembershipsService.patchMembership(data);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(GET_MEMBERSHIPS, { page: FIRST_PAGE });
    },
    onError: ({ response: { data } }) => {
      console.log(data);
    },
  });
};

const usePatchVariation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data) => {
      return MembershipsService.patchVariation(data);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(GET_MEMBERSHIPS, { page: FIRST_PAGE });
    },
    onError: ({ response: { data } }) => {
      console.log(data);
    },
  });
};

const useCreateVariation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) => {
      return MembershipsService.createVariation(payload);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(GET_MEMBERSHIPS, { page: FIRST_PAGE });
    },
    onError: ({ response: { data } }) => {
      console.log(data);
    },
  });
};

const useDisableVariation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) => {
      return MembershipsService.disableVariation(payload);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(GET_MEMBERSHIPS, { page: FIRST_PAGE });
    },
    onError: ({ response: { data } }) => {
      console.log(data);
    },
  });
};

const useCreateSubscription = (successMessage) => {
  return useMutation({
    mutationFn: (payload) => {
      return MembershipsService.createSubscription(payload);
    },
    onSuccess: () => {
      message.success(successMessage);
    },
    onError: ({ response: { data } }) => {
      unpackAndShowErrors(data);
    },
  });
};

const useGetContactCards = (payload) => {
  return useQuery({
    queryKey: [GET_CONTACT_CARDS, payload],
    queryFn: () => MembershipsService.getContactCards(payload),
  });
};

const useGetSubscriptions = (payload) =>
  useQuery({
    queryKey: [GET_SUBSCRIPTIONS, payload],
    queryFn: () => MembershipsService.getSubscriptions(payload),
  });

const useGetLoyaltySubscriptions = (payload) =>
  useInfiniteQuery({
    queryKey: [GET_LOYALTY_SUBSCRIPTIONS, payload],
    queryFn: ({ pageParam = FIRST_PAGE }) =>
      MembershipsService.getSubscriptions({ ...payload, page: pageParam }),
    getNextPageParam: (lastPage) => {
      if (lastPage.data.next) {
        return lastPage.data.current + 1;
      }
      return undefined;
    },
    initialPageParam: FIRST_PAGE,
  });

const useGetLoyaltyInvoices = (payload) =>
  useQuery({
    queryKey: [GET_LOYALTY_INVOICES, payload],
    queryFn: () => MembershipsService.getInvoices(payload),
    enabled: !!payload?.contact_id,
  });

const useCancelSubscription = (successMessage, onSuccess) => {
  return useMutation({
    mutationFn: (payload) => {
      return MembershipsService.cancelSubscription(payload);
    },
    onSuccess: () => {
      message.success(successMessage);
      onSuccess();
    },
    onError: ({ response: { data } }) => {
      unpackAndShowErrors(data);
    },
  });
};

export {
  useGetMemberships,
  usePatchVariation,
  useGetContactCards,
  useCreateVariation,
  usePatchMembership,
  useDisableVariation,
  useCreateMembership,
  useGetSubscriptions,
  useDisableMembership,
  useCancelSubscription,
  useCreateSubscription,
  useGetLoyaltyInvoices,
  useGetLoyaltyMemberships,
  useGetLoyaltySubscriptions,
};
