import { Card, Col, Row, Button } from "antd";
import { useIntl } from "react-intl";
import { getAsCurrency } from "helpers/getAsCurrency";
import { getLowestPrice } from "./utils";
import translations from "./translations";

const OfferMembershipCard = ({ membership, setSelectedMembership }) => {
  const { formatMessage } = useIntl();

  const handleSubscribe = () => {
    setSelectedMembership(membership);
  };

  const lowestPrice = getAsCurrency(getLowestPrice(membership.variations));
  return (
    <Card>
      <Row>
        <Col>
          <p className="mb-0 font-size-lg font-weight-bold">
            {membership.name}
          </p>
        </Col>
      </Row>
      <Row justify="middle">
        <Col span={12}>
          <p className="font-size-md mb-0">
            {formatMessage(translations.fromPrice, { price: lowestPrice })}
          </p>
        </Col>
        <Col span={12} align="right">
          <Button onClick={handleSubscribe}>
            {formatMessage(translations.subscribe)}
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default OfferMembershipCard;
