import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Card, Col, Row, Button } from "antd";
import { setCart } from "redux/actions/Cart";
import { makeSelectContactId } from "redux/selectors/Cart";
import OfferPhaseDescription from "./phaseOfferDescription";
import { getLocalStorageItem, setLocalStorageItem } from "utils/localStorage";
import MembershipPlanDetails from "shared/MembershipPlanDetails";
import { format } from "util";
import translations from "./translations";
import { ROUTES } from "routes";

const OfferMembershipPurchase = ({ membership, setSelectedMembership }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const contactId = useSelector(makeSelectContactId());

  const handleBrowseOtherPlans = () => {
    setSelectedMembership(null);
  };

  const handleAddToCart = (variation) => {
    const cart = getLocalStorageItem("cart");
    if (cart?.length) {
      dispatch(setCart([...cart, { membership, variation }]));
      setLocalStorageItem("cart", [...cart, { membership, variation }]);
    } else {
      dispatch(setCart([{ membership, variation }]));
      setLocalStorageItem("cart", [{ membership, variation }]);
    }
    history.push(format(ROUTES.LOYALTY_CART_CONTACT, contactId));
  };

  return (
    <div style={{ minWidth: "350px" }}>
      <Row>
        <Col>
          <p className="my-3 font-size-xxl font-weight-bold px-4">
            {membership.name}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="px-4">{membership.description}</p>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {membership.variations.map((variation) => (
            <Card key={variation.id} className="mt-2">
              <p className="font-size-md font-weight-bold mb-2">
                {variation.name}
              </p>
              {variation.phases.map((phase) => (
                <OfferPhaseDescription key={phase.id} phase={phase} />
              ))}
              <Row>
                <Col span={24}>
                  <MembershipPlanDetails benefits={variation?.benefits} />
                </Col>
              </Row>
              <Button
                className="w-100"
                onClick={() => handleAddToCart(variation)}
              >
                {formatMessage(translations.addToCart)}
              </Button>
            </Card>
          ))}
        </Col>
      </Row>
      <Button size="small" onClick={handleBrowseOtherPlans}>
        {formatMessage(translations.browseOtherPlans)}
      </Button>
    </div>
  );
};

export default OfferMembershipPurchase;
