// Corresponds to the feature name in the database - added by the admin
export const MEMBERSHIP_FEATURE = "Memberships";
export const MEMBERSHIP_FEATURE_KEY = "memberships";
export const MEMBERSHIP_NAVIGATION_IND = 5;

export const DAILY_CADENCE_FEATURE = "Membership Daily Cadence";

export const FEATURE_LIST = {
  MEMBERSHIPS: "Memberships",
  DAILY_CADENCE_FEATURE: "Membership Daily Cadence",
};
