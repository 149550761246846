export const DATE_FORMAT_YYYYMMdd = "YYYYMMdd";
export const DATE_FORMAT_YYYY_MM_dd = "YYYY-MM-dd";
export const DATE_FORMAT_YYYY_MM_DD = "YYYY-MM-DD";
export const DATE_FORMAT_MMM_DD_YYYY_US = "MMM DD, YYYY";
export const DATE_FORMAT_YYYY_MM_dd_Combined = "YYYYMMddHHmm";
export const DATE_FORMAT_YYYY_MM_dd_HH_mm = "MMM DD YYYY hh:mm a";
export const DATE_FORMAT_DD_MM_YYYY = "DD-MM-YYYY";
export const DATE_FORMAT_DD_MM_YYYY_WITH_DOT = "DD.MM.YYYY";
export const DATE_FORMAT_MM_DD_YYYY_WHIT_DOT = "MM.DD.YYYY";
export const DATE_TIME_FORMAT_DD_MM_YYYY_WITH_DOT = "DD.MM.YYYY. hh:mm a";
export const DATE_FORMAT_mm_dd_YYYY_WITH_SLASH = "MM/dd/YYYY";
export const DATE_FORMAT_m_d_YYYY_WITH_SLASH = "M/d/YYYY";
export const DATE_FORMAT_DD_MM_YYYY_WITH_SLASH = "DD/MM/YYYY";
export const DATE_FORMAT_dd_MMM = "ddMMM";
export const DATE_FORMAT_dd_MM_yy = "ddMMyy";
export const DATE_FORMAT_dd_MMM_YYYY = "ddMMMYYYY";
export const DATE_FORMAT_DD_MM_YYYY_COMBINED = "DDMMYYYY";
export const DATE_FORMAT_DD_MM_YYYY_HH_mm_ss = "YYYY-MM-dd'T'HH:mm:ss";
export const DATE_FORMAT_DD_MM_YYYY_HH_mm_ss_sz = "YYYY-MM-dd'T'HH:mm:ss.SSS";
export const DATE_FORMAT_DD_MM_YYYY_HH_mm = "dd-MM-YYYY HH:mm";
export const DATE_FORMAT_HH_mm = "hh:mm a";
export const DATE_FORMAT_YYYY_MM_DDTHH_mm_ssZ = "YYYY-MM-DD'T'HH:mm:ssZ";
export const DATE_COMMUNICATION_FORMAT = "MMM DD YYYY, hh:mm a";
export const DEFAULT_DATE_FORMAT = "ddd, MMM DD YYYY, hh:mm a";
export const DATE_FORMAT_LANDING_PAGE_NAME = "MM_DD_YY_h:mm_a";
export const DATE_MESSAGE_FORMAT = "hh:mm a";
export const DATE_COMMUNICATION_FORMAT_SINGLE = "MMM DD, YYYY";
export const DATE_PAYMENT = "MMM DD, YYYY";
export const DATE_CONVERSATION_FORMAT = "DD/MM/YYYY hh:mm A";
export const DATE_MMM_Do_YYY = "MMM Do YYYY";
export const DATE_YYYY_DD_MM = "YYYY-DD-MM";
export const SCHEDULER_DATE_FORMAT = "YYYY-DD-MM HH:mm a";
export const SCHEDULER_DATE_TOOLTIP_FORMAT = "YYYY-DD-MM hh:mm a";
export const DATE_FORMAT_HH = "hh:mm";

export const TIME_HH_MM_A = "h:mm A";
export const TIME_HH_MM_NO_A = "h:mm";
export const TIME_HH_MM = "HH:mm";
export const TIME_HH_MM_SS = "hh:mm:ss";
export const DATE_FORMAT_US = "MM/DD/YYYY";

export const DATE_CONVERSATION_FORMAT_US = "MM.DD.YYYY hh:mm A";
export const DATE_FORMAT_MM_DD_YYYY_US = "MM-DD-YYYY";
export const DATE_FORMAT_YYYY_DD_MM_US = "YYYY-DD-MM";
export const DATE_FORMAT_MMM_DD = "ddd MMM DD";
export const DATE_FORMAT_ddd_MMM_DD = "ddd, MMM DD";
export const DATE_FORMAT_MMM_DD_YYYY = "MMM DD YYYY";
export const DATE_FORMAT_MMMM_YYYY = "MMMM YYYY";
export const DATE_FORMAT_ddd_MMM_DD_YYYY = "ddd, MMM DD YYYY";
export const FULL_DATE_TIME_FORMAT = "YYYY-MM-DD hh:mm:ss";
export const DATE_TIME_YYYY_MM_DD_hh_a = "YYYY-MM-DD hh:mm a";
export const DATE_TIME_YYYY_MM_DD_hh = "YYYY-MM-DD HH:mm";
export const DATE_TIME_MM_DD_YYYY_hh_a = "MM-DD-YYYY hh:mm a";
export const DATE_TIME_MM_DD_YYYY_MM_DD_hh = "MM-DD-YYYY HH:mm";
export const TIME_hh_00_a = "hh:mm a";
export const DATE_TIME_YYYY_MM_DD_hh_A = "YYYY-MM-DD hh:mm A";
export const TIME_hh_00a = "hh:mma";
export const DATE_FORMAT_dddd_d_MMMM_YYYY = "ddd, D MMM YYYY";
export const DATE_FORMAT_DDDD_d_MMMM_YYYY = "dddd, D MMM YYYY";
export const DATE_FORMAT_dddd_DD_MMM_YYYY = "dddd DD MMM, YYYY";
export const DATE_FORMAT_DD_MMMM_YYYY = "DD MMMM YYYY";
export const TIME_HH_MM_a = "h:mm a";
