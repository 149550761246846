import ApiService from "./ApiService";
import { stringify } from "querystring";
import { format } from "util";

const ENDPOINTS = {
  GET_DASHBOARD_DATA: "analytics",
  GET_CONVERSION_DATA: "analytics/contact_conversions",
  GET_TODAY_STATS: "dashboard/appointments/today/stats/?date=%s",
  GET_DAILY_REVENUE: "dashboard/reporting/daily_revenue/?date=%s",
  GET_NOTES: "dashboard/notes/",
  UPDATE_NOTE: "dashboard/notes/%s/",
  DELETE_NOTE: "dashboard/notes/%s/",
  COMPLETE_NOTE: "dashboard/notes/%s/",
  GET_SERVICES: "dashboard/service/",
  GET_QUICK_BOOKING: "dashboard/quick-booking/",
};

class DashboardService extends ApiService {
  getDashboardStatsData = (payload) => {
    let queryParams = "";
    if (payload?.start_date && payload?.end_date) {
      queryParams = payload;
    }
    return this.apiClient.get(
      `${ENDPOINTS.GET_DASHBOARD_DATA}/?${stringify(queryParams)}`
    );
  };

  getDashboardConversionData = ({ payload }) => {
    return this.apiClient.get(
      `${ENDPOINTS.GET_CONVERSION_DATA}/?${stringify(payload)}`
    );
  };

  getTodayStats = async (date) => {
    return this.apiClient.get(format(ENDPOINTS.GET_TODAY_STATS, date));
  };

  getDailyRevenue = async (date) => {
    return this.apiClient.get(format(ENDPOINTS.GET_DAILY_REVENUE, date));
  };

  getNotes = async (date) => {
    return this.apiClient.get(`${ENDPOINTS.GET_NOTES}?date=${date}`);
  };

  createNote = async (data) => {
    return this.apiClient.post(ENDPOINTS.GET_NOTES, data);
  };

  updateNote = async (noteId, data) => {
    return this.apiClient.patch(format(ENDPOINTS.UPDATE_NOTE, noteId), data);
  };

  deleteNote = async (noteId) => {
    return this.apiClient.delete(format(ENDPOINTS.DELETE_NOTE, noteId));
  };

  completeNote = async (noteId, data) => {
    return this.apiClient.patch(format(ENDPOINTS.COMPLETE_NOTE, noteId), data);
  };

  getServices = async (page, params = {}) => {
    const query = stringify({
      page,
      ...params,
    });
    return this.apiClient.get(`${ENDPOINTS.GET_SERVICES}?${query}`);
  };

  getQuickBooking = async ({ date, employeeId, serviceId }) => {
    const query = stringify({
      date,
      employee: employeeId,
      service: serviceId,
    });
    return this.apiClient.get(`${ENDPOINTS.GET_QUICK_BOOKING}?${query}`);
  };
}

export default new DashboardService();
