import { DOLLAR_CURRENCY } from "constants/Dashboard";
import { NOT_APPLICABLE } from "constants/Dashboard";

export const getAsCurrency = (amount, noDecimal = false, currency = DOLLAR_CURRENCY) => {
  if (amount) {
    if (typeof amount == "string") {
      return `${currency}${parseFloat(amount).toFixed(noDecimal ? 0 : 2)}`;
    }
    return `${currency}${amount.toFixed(noDecimal ? 0 : 2)}`;
  }
  return NOT_APPLICABLE;
};
