export const PAYMENT_STRIPE = "stripe";
export const PAYMENT_MANUAL = "manual";
export const PAYMENT_SQUARE = "square";
export const PAYMENT_UNKNOWN = "unknown";
export const PAYMENT_SUBSCRIPTION = "subscription";

export const PAYMNET_TYPE_COLORS = {
  [PAYMENT_STRIPE]: "#5433ffd9",
  [PAYMENT_MANUAL]: "#ce0538d9",
  [PAYMENT_SQUARE]: "#89cff0d9",
  [PAYMENT_UNKNOWN]: "#89c550d9",
};

export const TRANSACTIONS_PAGE_SIZE = 10;
export const TRANSACTIONS_PAGE_SIZE_5 = 5;
export const TREATMENTS_PAGE_SIZE = 10;
export const FIRST_PAGE = 1;
export const PER_PAGE = 10;
export const MODAL_PER_PAGE = 5;
export const MODAL_PAGE_SIZE = 5;
export const SMS_TEMPLATES_PER_PAGE = 10;
export const MAX_NUM_OF_TRANSACTIONS = 10;

export const DEFAULT_ORDERING = "-date";
export const DEFAULT_ORDERING_CREATED_AT = "-created_at";
export const ASCEND_SORT = "ascend";

export const ORDER_FIELDS = {
  date: "date",
  amount: "amount",
};

export const REFETCH_DOWNLOAD_TIME = 500;
export const FILTERS_CHANGE_CHECK = [
  "type",
  "search",
  "end_date",
  "start_date",
];

export const AWARD_STATS_COLOR = "#3F8600";
export const NA = "N/A";

export const ARCHIVED = "archived";
export const IN_FUTURE = "future";

export const MODAL_CONTENT = {
  SMART: "SMART",
  CONTACTS: "CONTACTS",
  APPOINTMENTS: "APPOINTMENTS",
  OTHER_APPOINTMENTS: "OTHER_APPOINTMENTS",
};
