import { ASCEND_SORT, DEFAULT_ORDERING } from "constants/ReportingsConstants";

const extractOrderingFields = (
  sorter,
  extra,
  defaultOrdering = DEFAULT_ORDERING
) => {
  if (extra?.action == "sort") {
    const orderField = sorter.order == ASCEND_SORT ? "" : "-";
    const ordering = `${orderField}${sorter?.field}`;
    return ordering;
  }
  return defaultOrdering;
};

export default extractOrderingFields;
