import React, { lazy, Suspense } from "react";
import { Switch, Redirect } from "react-router-dom";
import {
  APP_LOYALTY_PREFIX_PATH,
  AUTH_LOYALTY_PREFIX_PATH,
} from "configs/AppConfig";
import Loading from "components/shared-components/Loading";
import PrivatePatientRoute from "shared/PrivatePatientRoute";
import MembershipsPage from "./MembershipsPage";
import CartPage from "./Cart/cartPage";
import CheckoutPage from "./Cart/checkoutPage";

export const AppLoyaltyViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <PrivatePatientRoute
          path={`${APP_LOYALTY_PREFIX_PATH}/home`}
          component={lazy(() =>
            import("../../loyalty-app/app-loyalty-views/LoyaltyPage/index")
          )}
          exact
        />
        <PrivatePatientRoute
          path={`${APP_LOYALTY_PREFIX_PATH}/memberships`}
          component={MembershipsPage}
          exact
        />
        <PrivatePatientRoute
          path={`${APP_LOYALTY_PREFIX_PATH}/cart/:contact_id`}
          component={CartPage}
          exact
        />
        <PrivatePatientRoute
          path={`${APP_LOYALTY_PREFIX_PATH}/checkout/:contact_id`}
          component={CheckoutPage}
          exact
        />
        <PrivatePatientRoute
          path={`${APP_LOYALTY_PREFIX_PATH}/edit-profile`}
          component={lazy(() => import(`../../app-views/components/Profile`))}
          exact
        />
        <PrivatePatientRoute
          path={`${APP_LOYALTY_PREFIX_PATH}/edit-password`}
          component={lazy(() =>
            import(`../../app-views/components/ChangePassword`)
          )}
          exact
        />
        <Redirect
          from={`${APP_LOYALTY_PREFIX_PATH}`}
          to={`${AUTH_LOYALTY_PREFIX_PATH}/login`}
        />
      </Switch>
    </Suspense>
  );
};

export default AppLoyaltyViews;
