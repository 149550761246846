import React from "react";
import { Row, Col } from "antd";
import { getAsCurrency } from "helpers/getAsCurrency";
import { PLURAL, SINGULAR, CYCLES_NUMBER } from "constants/MembershipConstants";
import MembershipPlanDetails from "shared/MembershipPlanDetails";

export const cadencePluralOrSingular = (period) => {
  return period > 1 ? PLURAL : SINGULAR;
};

export const PhaseDescription = ({ phase }) => {
  const cadence =
    CYCLES_NUMBER[cadencePluralOrSingular(phase?.period)][phase?.cadence];

  const isFreeTrail = phase?.price_amount == 0;

  return (
    <>
      <Row gutter={10} align="middle">
        <Col offset={3} span={4}>
          <p className="font-weight-bold">{phase?.period}</p>
        </Col>
        <Col span={8}>
          <p>{isFreeTrail ? cadence : phase?.cadence}</p>
        </Col>
        <Col span={6} className="d-flex justify-content-end">
          <p className="font-weight-bold">
            {getAsCurrency(phase?.price_amount)}
          </p>
        </Col>
      </Row>
    </>
  );
};

const VariationsCard = ({ variation }) => {
  return (
    <div className="border rounded m-3 shadow-sm p-3">
      <Row>
        <Col offset={2} span={20}>
          <h4 className="w-100 break-word font-weight-lighter text-center">
            {variation?.name}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="my-2">
          {variation?.phases?.map((phase) => (
            <PhaseDescription phase={phase} key={phase?.id} />
          ))}
        </Col>
      </Row>
      {variation?.benefits?.length > 0 && (
        <Row>
          <Col span={24}>
            <MembershipPlanDetails benefits={variation?.benefits} />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default VariationsCard;
