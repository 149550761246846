import React from "react";
import { Row } from "antd";
import { getAsCurrency } from "helpers/getAsCurrency";
import {
  COUPON,
  CUSTOM_PRICE,
  DISCOUNT,
  PERCENTAGE,
  INCLUDED,
} from "constants/MembershipConstants";

const CustomPriceItem = ({ item }) => {
  const label =
    item?.unit_type === "PRODUCT"
      ? `${getAsCurrency(item.discount_amount)} - ${
          item?.content_object?.name || ""
        }`
      : `${item?.content_object?.name || ""} - ${getAsCurrency(
          item.discount_amount
        )} / ${item?.unit_type?.toLowerCase() || ""}`;

  return <li>{label}</li>;
};

const CouponItem = ({ item }) => {
  return (
    <li>{`${getAsCurrency(item.discount_amount)} - ${item?.benefit_type}`}</li>
  );
};

const DiscountItem = ({ item }) => {
  const discountType =
    item?.discount_type === PERCENTAGE
      ? `${item?.discount_amount}% off per ${
          item?.unit_type?.toLowerCase() || ""
        }`
      : `${getAsCurrency(item?.discount_amount)} off per ${
          item?.unit_type?.toLowerCase() || ""
        }`;

  return <li>{`${item?.content_object?.name || ""} - ${discountType}`}</li>;
};

const IncludedItem = ({ item }) => {
  return (
    <li>
      {`${item?.max_units || ""} `}
      <span className="text-lowercase">{`${item?.unit_type || ""} `}</span>
      <span className="text-capitalize">{`${
        item?.content_object?.name || ""
      } `}</span>
      <span className="font-weight-bold">{"Included"}</span>
    </li>
  );
};

const BenefitsList = ({ items }) => {
  const renderItem = (item) => {
    switch (item?.benefit_type) {
      case COUPON:
        return <CouponItem item={item} />;
      case CUSTOM_PRICE:
        return <CustomPriceItem item={item} />;
      case DISCOUNT:
        return <DiscountItem item={item} />;
      case INCLUDED:
        return <IncludedItem item={item} />;
      default:
        return null;
    }
  };

  return (
    <>
      {items.map((item) => (
        <div key={item.id}>
          <Row align="middle" className="px-3">
            <ul>{renderItem(item)}</ul>
          </Row>
        </div>
      ))}
    </>
  );
};

const MembershipPlanDetails = ({ benefits }) => {
  return (
    <div className="card-bg-color-details border rounded shadow-sm py-3 mt-1 mb-3">
      {benefits?.length > 0 && <BenefitsList items={benefits} />}
    </div>
  );
};

export default MembershipPlanDetails;
